import React, { useContext } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Grid, Typography, makeStyles, Button } from '@material-ui/core';

import { ListItem } from '../../CustomStyles/Typography';
import { FadeIn } from '../FadeIn';
import FormModalContext from '../../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	featureCard: {
		padding: '2rem',
		background: '#FFFFFF',
		margin: '.5rem 0',
		cursor: 'pointer',
		border: '1px solid #E5EAF4',
		borderRadius: '10px',
		transition: 'all 0.35s ease !important',
	},
	// cardBody: {
	// 	lineHeight: '32px',
	// 	fontWeight: 400,
	// 	marginTop: '1rem',
	// },
	button: {
		transition: 'all 0.35s ease !important',
		background: '#3FA529',
		'&:hover': {
			background: '#3FA529',
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
	header: {
		lineHeight: '51px',
		fontSize: '2.75rem',
	},
	body: { lineHeight: '32px' },
	// scroll: {
	// 	maxHeight: '700px',
	// 	overflowY: 'scroll',
	// 	'&::-webkit-scrollbar': {
	// 		display: 'none',
	// 	},
	// },
	card: {
		opacity: 1,
		background: 'white',
		margin: '2rem',
		padding: '2rem',
		borderRadius: '20px',
	},
}));

export const DesktopFeatures = ({ cards, leftContent, ctaText }) => {
	const classes = useStyles();
	const { handleModalClick } = useContext(FormModalContext);

	return (
		<Grid
			container
			alignItems='flex-start'
			justifyContent='space-between'
			spacing={4}
			style={{ position: 'relative' }}>
			<Grid
				container
				direction='column'
				item
				xs={5}
				style={{ position: 'sticky', top: '30%', height: '400px' }}>
				<PortableText
					content={leftContent}
					serializers={{
						h2: ({ children }) => (
							<Typography
								variant='h2'
								style={{ color: 'white' }}
								className={classes.header}>
								{children}
							</Typography>
						),
						strong: ({ children }) => (
							<Typography
								variant='h2'
								style={{ color: '#3FA529', fontSize: '2.75rem' }}>
								{children}
							</Typography>
						),
						normal: ({ children }) => (
							<Typography
								variant='body1'
								style={{ color: 'white' }}
								className={classes.body}>
								{children}
							</Typography>
						),
					}}
				/>

				<Grid container item>
					<Button
						variant='contained'
						size='large'
						className={classes.button}
						onClick={handleModalClick}
						style={{
							color: 'white',
							// marginLeft: '1rem',
							marginTop: '1rem',
							padding: '16px 32px',
						}}>
						{ctaText}
					</Button>
				</Grid>
			</Grid>
			<Grid
				container
				justifyContent='flex-start'
				alignItems='center'
				item
				xs={7}
				spacing={2}>
				{cards.map((card, index) => (
					<FadeIn>
						<div key={index} className={classes.card}>
							<Typography variant='h2' color='primary'>
								{card.header}
							</Typography>

							<Grid
								item
								container
								direction='column'
								alignItems='flex-start'
								justifyContent='space-evenly'>
								<PortableText
									content={card._rawBody}
									// className={classes.text}
									serializers={{
										h2: ({ children }) => (
											<Typography
												variant='h2'
												style={{
													lineHeight: 0.9,
													marginBottom: '2rem',
												}}>
												{children}
											</Typography>
										),
										normal: ({ children }) => (
											<Typography
												variant='body1'
												style={{
													fontSize: '1.5rem',
													color: 'white',
													fontFamily: 'Roboto',
												}}>
												{children}
											</Typography>
										),
										li: ({ children }) => (
											<Grid item style={{ marginBottom: '1rem' }}>
												<ListItem>
													<li>{children}</li>
												</ListItem>
											</Grid>
										),
									}}
								/>
							</Grid>
							<Grid item>
								<GatsbyImage
									style={{ maxWidth: 500 }}
									image={card.image?.asset.gatsbyImageData}
								/>
							</Grid>
						</div>
					</FadeIn>
				))}
			</Grid>
		</Grid>
	);
};
