import React from 'react';
import PortableText from 'react-portable-text';

import {
	Container,
	Typography,
	Grid,
	useMediaQuery,
	makeStyles,
	Button,
} from '@material-ui/core';

import { MobileFeatures } from './Mobile/MobileFeatures';
import { DesktopFeatures } from './DesktopFeatures';

const useStyles = makeStyles((theme) => ({
	header: {
		textAlign: 'center',
		marginBottom: '2rem',
		fontSize: '2.25rem',
		[theme.breakpoints.down('sm')]: {
			margin: '0',
		},
	},
	body: { lineHeight: '32px', textAlign: 'center' },
	button: {
		transition: 'all 0.35s ease !important',
		background: '#3FA529',
		'&:hover': {
			background: '#3FA529',
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
}));

export const CenterFeatures = ({ cards, ctaText, leftContent }) => {
	const classes = useStyles();
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Container>
			{/* <Typography variant='h2' color='primary' className={classes.header}>
				{header}
			</Typography> */}
			{md ? (
				<>
					<Grid
						container
						// direction='column'
						alignItems='center'
						justifyContent='center'
						style={{ padding: '2rem 0 0 0' }}>
						<PortableText
							content={leftContent}
							serializers={{
								h2: ({ children }) => (
									<Typography
										variant='h2'
										style={{ color: 'white' }}
										className={classes.header}>
										{children}
									</Typography>
								),
								strong: ({ children }) => (
									<Typography
										variant='h2'
										style={{ color: '#3FA529', fontSize: '2.75rem' }}>
										{children}
									</Typography>
								),
								normal: ({ children }) => (
									<Typography
										variant='body1'
										style={{ color: 'white' }}
										className={classes.body}>
										{children}
									</Typography>
								),
							}}
						/>

						<Grid container item alignItems='center' justifyContent='center'>
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								style={{
									color: 'white',
									// marginLeft: '1rem',
									marginTop: '1rem',
									padding: '16px 32px',
								}}>
								{ctaText}
							</Button>
						</Grid>
					</Grid>
					<MobileFeatures featureSection={cards} />{' '}
				</>
			) : (
				<DesktopFeatures
					cards={cards}
					ctaText={ctaText}
					leftContent={leftContent}
				/>
			)}
		</Container>
	);
};
